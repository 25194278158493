import { useEffect } from 'react';
import { scroller } from 'react-scroll';
import ScrollReveal from 'scrollreveal';
import Rellax from 'rellax';

import ContactForm from '../components/ContactForm';
import Layout from '../components/Layout';

function Home() {
	useEffect(() => {
		ScrollReveal().reveal('#heading', {
			delay: 250,
			distance: '50px',
			duration: 2000,
		});

		ScrollReveal().reveal('.feature, #contactHeading', {
				delay: 250,
				distance: '50px',
				viewFactor: 0.3,
				duration: 2000,
		});

		const relax = new Rellax('.parallax');
		return () => relax.destroy();
	}, []);

	const handleScroll = (e, id) => {
		e.preventDefault();
		scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Layout>
			<section
				className="bg-cover bg-center relative"
				style={{ backgroundImage: 'url(https://prysym.com/images/hero.png)' }}
			>
				<div className="container mx-auto pt-16 lg:pt-30 pb-40 lg:pb-66 px-6 lg:px-20 max-w-7xl">
					<div className="flex flex-col lg:flex-row items-center justify-between mb-16 lg:mb-32 xl:mb-42">
						<img alt="Logo" src="https://prysym.com/images/logo.svg" className="w-42 mb-6 lg:mb-0" />
						<ul className="flex flex-col lg:flex-row text-center">
							<li className="mb-5 lg:mb-0 lg:ml-20">
								<a
									href="/"
									className="uppercase font-medium font-sans text-2xs text-white tracking-widest border-b-2 hover:text-pink-500 pb-4 hover:border-pink-500 border-transparent"
									onClick={(e) => handleScroll(e, 'solutions')}
								>
									Solutions
								</a>
							</li>
							<li className="mb-5 lg:mb-0 lg:ml-20">
								<a
									href="/"
									className="uppercase font-medium font-sans text-2xs tracking-widest border-b-2 text-pink-500 pb-4 border-pink-500"
									onClick={(e) => handleScroll(e, 'contact')}
								>
									Get in touch
								</a>
							</li>
						</ul>
					</div>
					
					<div
						className="load-hidden"
						id="heading"
					>
						<h1
							data-rellax-speed="-1"
							data-rellax-percentage="0"
							className="parallax text-center lg:text-left text-white text-5xl lg:text-7xl font-light max-w-md lg:max-w-4xl leading-tight mx-auto lg:ml-0"
						>
            	A new, better way to enhance payments.
            </h1>
					</div>
				</div>
				
				<img
					alt="Lines"
					data-rellax-speed="-5"
					data-rellax-percentage="0"
					src="https://prysym.com/images/lines.svg"
					className="parallax absolute right-0 -mt-16 lg:-mt-60 w-11/12 lg:w-1/2 pointer-events-none"
				/>
			</section>

			<section id="solutions" className="py-20">
				<div className="container mx-auto px-6 lg:px-20 max-w-7xl">
					<p data-rellax-speed="-0.5" data-rellax-percentage="0" className="parallax uppercase font-sans font-medium text-lg tracking-widest text-gray-200 mb-20">
            Solutions
					</p>
					<div className="px-6 lg:px-24">
						<div className="flex flex-col lg:flex-row">
							<div>
								<div className="feature load-hidden mb-40">
									<div>
										<h3 className="text-pink-500 font-medium max-w-sm2 mb-10 text-[40px]">
											Regulated Entity Solutions
										</h3>
										<ul data-rellax-percentage="0.8" data-rellax-speed="-0.5" className="parallax">
											<li className="text-gray-500 font-sans text-xl mb-5">Platform Powers 200+ Banks</li>
											<li className="text-gray-500 font-sans text-xl mb-5">Debit &amp; Prepaid Issuing Processing</li>
											<li className="text-gray-500 font-sans text-xl mb-5">Digital/Crypto Asset &amp; Multi Currency Ledger</li>
											<li className="text-gray-500 font-sans text-xl mb-5">Robust, Comprehensive Compliance</li>
											<li className="text-gray-500 font-sans text-xl mb-5">Complete Business &amp; Consumer Products</li>
										</ul>
									</div>
								</div>

								<div className="feature load-hidden mb-40 lg:mb-0">
									<div>
										<h3 className="text-pink-500 font-medium max-w-sm2 mb-10 text-[40px]">
											Value Added Options
										</h3>
										<ul data-rellax-percentage="0.8" data-rellax-speed="-0.5" className="parallax">
											<li className="text-gray-500 font-sans text-xl mb-5">Faster Payments</li>
											<li className="text-gray-500 font-sans text-xl mb-5">SMS, Chat &amp; Agent Support</li>
											<li className="text-gray-500 font-sans text-xl mb-5">Creative Services &amp; Digital Marketing</li>
											<li className="text-gray-500 font-sans text-xl mb-5">Mobile &amp; Web App Development</li>
											<li className="text-gray-500 font-sans text-xl mb-5">Integrated SMS &amp; Email Tools</li>
											<li className="text-gray-500 font-sans text-xl mb-5">Robust APIs</li>
										</ul>
									</div>
								</div>
							</div>
					
							<div className="feature load-hidden flex-grow flex items-center lg:justify-end lg:text-right">
								<div>
									<h3 className="text-pink-500 font-medium max-w-sm2 mb-10 text-[40px]">
										Merchant &amp; Business Solutions
									</h3>
									<ul data-rellax-percentage="0.8" data-rellax-speed="-0.5" className="parallax">
										<li className="text-gray-500 font-sans text-xl mb-5">Global B2B &amp; B2C (accept &amp; make) Payments</li>
										<li className="text-gray-500 font-sans text-xl mb-5">Business Process Automation</li>
										<li className="text-gray-500 font-sans text-xl mb-5">In-place, Global Bank/BIN Sponsors</li>
										<li className="text-gray-500 font-sans text-xl mb-5">Digitized eDoc Integration</li>
										<li className="text-gray-500 font-sans text-xl mb-5">Fast Co-Brand or White Label</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="contact" className="bg-gray-100 pt-20 pb-30">
				<div className="container mx-auto px-6 lg:px-20 max-w-7xl">
					<p className="uppercase font-sans font-medium text-lg tracking-widest text-gray-200 mb-20">
						Get in touch
          </p>
					<h3 id="contactHeading" className="load-hidden text-gray-800 font-medium text-4xl lg:text-[3rem] mb-30 leading-normal">
            Get in touch<br />and learn how we can power your payments
          </h3>

					<ContactForm />
				</div>
			</section>
    </Layout>
  );
}

export default Home;
