import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function ContactForm() {
	const [success, setSuccess] = useState(false);

	const sendEmail = (values) => {
		emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, values, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
				if (result.text === 'OK') {
					setSuccess(true);
				}
      });
	};

	const formik = useFormik({
		initialValues: {
			email: '',
			name: '',
			phone: '',
			message: '',
			recaptcha: '',
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.min(5, 'Minimum 5 characters')
				.required('This field is required'),
			message: Yup.string()
				.min(15, 'Minimum 15 characters')
				.required('This field is required'),
			phone: Yup.string()
				.min(6, 'Minimum 6 characters')
				.required('This field is required'),
			email: Yup.string().email('Enter a valid email')
				.required('This field is required'),
			recaptcha: Yup.string().required('This field is required'),
		}),
		onSubmit: values => {
			sendEmail(values);
		},
	});

	const handleRecaptcha = (value) => {
		formik.setFieldValue('recaptcha', value || '');
	};

  return (
    <div className="px-6 lg:px-24">
			{success ? (
				<div className="font-sans text-2xl text-gray-500 max-w-xl">
					<div className="mb-4">We appreciate you taking the time to write to us.</div>
					We'll get in touch with you within 48 business hours to learn more about your payment needs.
				</div>
			) : (
				<form onSubmit={formik.handleSubmit}>
					<div className="flex flex-col lg:flex-row lg:-mx-16">
						<div className="w-full lg:w-1/2 lg:px-16">
							<div className="mb-8">
								<label htmlFor="name" className="block text-[#9AA7AF] font-light">
									Name, Surname
								</label>
								<div className={`${formik.errors.name && formik.touched.name ? 'border-red-400 focus-within:border-red-600' : 'border-gray-300 focus-within:border-gray-600'} mt-1 border-b`}>
									<input
										id="name"
										name="name"
										type="text"
										className={`${formik.errors.name && formik.touched.name ? 'focus:border-red-600' : 'focus:border-gray-600'} text-[24px] font-light block w-full border-0 border-b border-transparent text-gray-700 bg-gray-100 focus:ring-0 placeholder-gray-300 focus-visible:outline focus:outline-none`}
										placeholder="Jeremy White"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.name}
									/>
								</div>
								{formik.errors.name && formik.touched.name && (
									<p className="text-red-400 text-xs mt-2">{formik.errors.name}</p>
								)}
							</div>
							
							<div className="mb-8">
								<label htmlFor="email" className="block text-[#9AA7AF] font-light">
									Email
								</label>
								<div className={`${formik.errors.email && formik.touched.email ? 'border-red-400 focus-within:border-red-600' : 'border-gray-300 focus-within:border-gray-600'} mt-1 border-b`}>
									<input
										id="email"
										name="email"
										type="text"
										className={`${formik.errors.email && formik.touched.email ? 'focus:border-red-600' : 'focus:border-gray-600'} text-[24px] font-light block w-full border-0 border-b border-transparent text-gray-700 bg-gray-100 focus:ring-0 placeholder-gray-300 focus-visible:outline focus:outline-none`}
										placeholder="jeremy.white@companyname.com"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.email}
									/>
								</div>
								{formik.errors.email && formik.touched.email && (
									<p className="text-red-400 text-xs mt-2">{formik.errors.email}</p>
								)}
							</div>

							<div className="mb-8">
								<label htmlFor="phone" className="block text-[#9AA7AF] font-light">
									Phone Number
								</label>
								<div className={`${formik.errors.phone && formik.touched.phone ? 'border-red-400 focus-within:border-red-600' : 'border-gray-300 focus-within:border-gray-600'} mt-1 border-b`}>
									<input
										id="phone"
										name="phone"
										type="number"
										className={`${formik.errors.phone && formik.touched.phone ? 'focus:border-red-600' : 'focus:border-gray-600'} appearance-none text-[24px] font-light block w-full border-0 border-b border-transparent text-gray-700 bg-gray-100 focus:ring-0 placeholder-gray-300 focus-visible:outline focus:outline-none`}
										placeholder="1 555 000 1111"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.phone}
									/>
								</div>
								{formik.errors.phone && formik.touched.phone && (
									<p className="text-red-400 text-xs mt-2">{formik.errors.phone}</p>
								)}
							</div>
						</div>
						<div className="w-full lg:w-1/2 lg:px-16">
							<div className="mb-8">
								<label htmlFor="message" className="block text-[#9AA7AF] font-light">
									Message
								</label>
								<div className={`${formik.errors.message && formik.touched.message ? 'border-red-400 focus-within:border-red-600' : 'border-gray-300 focus-within:border-gray-600'} mt-1 border-b`}>
									<textarea
										id="message"
										name="message"
										className={`${formik.errors.message && formik.touched.message ? 'focus:border-red-600' : 'focus:border-gray-600'} text-[24px] font-light block w-full border-0 border-b border-transparent text-gray-700 bg-gray-100 focus:ring-0 placeholder-gray-300 focus-visible:outline focus:outline-none`}
										placeholder="Your message please"
										rows={4}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.message}
									></textarea>
								</div>
								{formik.errors.message && formik.touched.message && (
									<p className="text-red-400 text-xs mt-2">{formik.errors.message}</p>
								)}
							</div>

							<div className="text-center lg:text-right">
								<ReCAPTCHA
									sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
									onChange={handleRecaptcha}
								/>
								{formik.errors.recaptcha && formik.touched.recaptcha && (
									<p className="text-red-400 text-xs mt-2 text-left">{formik.errors.recaptcha}</p>
								)}
								<button
									type="submit"
									className="mt-5 w-full border-2 border-gray-500 normal-case text-blue-500 h-14 px-20 mx-0 text-base hover:border-gray-200 focus:border-blue-500 focus:bg-blue-500 focus:text-white focus:outline-none"
								>
									Submit
								</button>
								<div className="mt-2 text-gray-600 text-xs font-light text-left">
									By submitting this form you agree with out
									&nbsp;<Link to="/privacy" className="text-blue-500 border-b border-transparent pb-px hover:border-blue-500">Privacy Policy</Link>&nbsp;
									terms.
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
		</div>
  );
}

export default ContactForm;
